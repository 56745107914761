.questionnaire-content.family-tree {
  margin-top: unset;
}

.questionnaire-footer {
  position: fixed;
  left: 1px;
  bottom: 1px;
  height: 80px;
}

@media screen and (min-width: 600px) {
  .questionnaire-wrapper {
    background: #FFFFFF;
    height: calc(100vh - 160px);
    overflow-y: scroll;
  }
}

@media screen and (max-width: 600px) {
  .questionnaire-wrapper {
    background: #FFFFFF;
    height: calc(100vh - 240px);
    overflow-y: scroll;
  }
}

.questionnaire-inner-content {
  position: relative;
}

.questionnaire-content {
  padding: 25px;
}


.questionnaire-content h2:not(:first-of-type) {
  margin-bottom: 40px;
}

.dropdown-input {
  font-size: 1rem;
  padding-left: 10px;
  height: 50px;
  background: #EAEDF0;
  border-radius: 10px;
  border: 1px solid #FFFFFF;
  color: #1B6481;
}

.style-add-biopsy {
  margin-right: 10px;
}

.style-add-biopsy:before {
  content: '\f055';
}

.multi-horiz-dropdown div:not(:last-child) {
  margin-right: 50px;
}

.blue-emphasis {
  color: #2DA5B9;
}

.text-input-small {
  height: 50px;
  font-size: 1rem;
  transform: matrix(1, 0, 0, 1, 0, 0);
  background: #EAEDF0 0 0 no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  letter-spacing: 0;
  color: #1B6481;
  border: 1px solid #FFFFFF;
  width: 120px;
  padding-left: 10px;
}

.plus-button {
  color: #1B6481;
  font-size: 25px;
}

.plus-button:before {
  content: '\f055';
}

.add-button {
  all: unset;
  align-self: center;
  margin-left: 20px;
  height: 24px;
  width: 24px;
  cursor: pointer;
}