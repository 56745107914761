.card-wrapper-dashboard {
  padding: 50px;
  border: 1px solid lightgrey;
  border-radius: 10px;
  background-color: white;
}

.blue-text {
  font-size: 22px;
  font-weight: normal;
}
