
h4 {
  position: relative;
  display: inline-block;
}

h4:before {
  content: "";
  position: absolute;
  width: 54px;
  bottom: -6px;
  border-bottom: 5px solid #f27931;
  border-radius: 5px;
}

.signup-info {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  margin: 10px 0;
}

.loginForm {
  padding: 25px;
  background: #ffffff;
  border-radius: 0 15px 15px 0;
}

.img-logo {
  width: 291px;
  height: 85px;
}

.text-input {
  padding-left: 10px;
}

.text-input-wide {
  padding-left: 10px;
  width: 100%
}

.pass-req {
  color: green;
}

.pass-req-not {
  color: red;
}

.error-text {
  font-weight: bold;
  font-size: 18px;
}

.underline {
  text-decoration: underline;
}