/***************************************************************
* The idea is to have some global styles applicable to all components
* and to have local styles specific to each component.
* each component & page should have its own css file.
* App.css and index.css should be considered 'global' styles.
* index.css is broader than App.css, App.css should specify 
* initial styling for all components. index.css generally
* specifies the styling for HTML elements.
****************************************************************/

/*
color palette:
main orange: #F27931
second orange: #FC7556 (gradient)
pale orange: #FFEFE4
main teal: #2DA5B9
main teal-light: #D8EEF2
second teal: #1B6481
secondary teal-light: #DDE8EC
error red: #FF5638
*/

@font-face {
  font-family: 'Open Sans';
  src: url('../public/Fonts/OpenSans-SemiBold.ttf') format("truetype");
}

@font-face {
  font-family: 'Open Sans Regular';
  src: url('../public/Fonts/OpenSans-Regular.ttf') format("truetype");
}

@font-face {
  font-family: 'Open Sans Light';
  src: url('../public/Fonts/OpenSans-Light.ttf') format("truetype");
}

body {
  margin: 0;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  background-color: #D8EEF2 !important;
}

h1 {
  font-family: 'Open Sans', 'Roman', 'Helvetica', 'sans-serif';
  font-size: 40px !important;
  color: #1B6481;
  font-weight: bold !important;
  margin-top: 15px !important;
}

h1.white {
  color: #ffffff;
}

h2 {
  font-family: 'Open Sans', 'Helvetica', 'sans-serif';
  font-size: 30px !important;
  color: #1B6481;
  font-weight: bold !important;
}

h3 {
  font-family: 'Open Sans', 'Helvetica', 'sans-serif';
  font-size: 26px !important;
  color: #1B6481;
  font-weight: bold !important;
}

h4 {
  font-family: 'Open Sans', 'Helvetica', 'sans-serif';
  font-size: 22px !important;
  color: #1B6481;
  font-weight: bold !important;
}

h5 {
  font-family: 'Open Sans', 'Helvetica', 'sans-serif';
  font-size: 22px !important;
  color: #232323;
}

h6 {
  font-family: 'Open Sans', 'Helvetica', 'sans-serif';
  font-size: 20px !important;
  color: #232323;
}

label {
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  color: #304759;
}

.extra-label-orange {
  text-align: center;
  margin: 20px 0;
  color: #c93e00;
  font-style: italic;
  font-size: 19px;
  font-weight: 500;
}

.sublabel {
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  color: #304759;
  align-self: center;
}

.style-fa {
  margin-left: 20px;
  margin-right: 20px;
  font-size: 2em;
  color: #22a5ba
}

.attention {
  color: red;
  font-weight: bold;
  font-size: 24px;
}

.error-text {
  color: #FF5638
}

.success-text {
  font-weight: bold;
  color: #05a200
}

#footer {
  position: absolute;
  left: 0;
  bottom: -150px;
  right: 0;
  color: white;
  height: 40px;
  margin-top: 20px;
  background-color: #1B6481;
}

.blue-text {
  color: #1B6481 !important;
  font-weight: bold;
}

.padding25px {
  padding: 25px;
}

.teal-gradient {
  background-image: linear-gradient(180deg, #1B6481 0%, #23A6BA 100%);
}

.bad-error {
  width: 600px;
}

