.loading-spinner {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 0.2s;
}

.loading-spinner {
  visibility: hidden;
  opacity: 0;
}

.lottie-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading-spinner.show {
  visibility: visible;
  opacity: 1;
}