@media screen and (max-width: 1365px) {
}

.form-loading {
  display: none;
}

.resources-input {
  height: 40px;
  border-radius: 10px;
  border: none;
  margin-right: 20px;
  width: 300px;
}

.resources-input::placeholder {
  color: #23a6ba;
  font-weight: bold;
}

