.age-input {
  width: 60px;
}

#gene-delete > span {
  color: #1B6481;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 5px;
  margin-left: -25px;
  position: absolute;
}