.family-tree-labels {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
}

.family-tree-labels > label:first-child {
  margin-right: 100px;
  position: relative;
  top: 25px;
}

.family-tree-counter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

.counter-holder-left, .counter-holder-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.count-holder {
  padding: 5px 15px;
  background: #EAEDF0;
  color: #1B6481;
}

.counter-holder-right {
  margin-right: 50px;
}

.minus-button, .addition-button {
  width: 30px;
  height: 30px;
  border: none;
  background: none;
  padding: 0;
  color: #1B6481;
  font-size: 36px;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.minus-button.minus-disabled {
  color: #EAEDF0;
  cursor: default;
}

.addition-button.addition-disabled {
  color: #EAEDF0;
  cursor: default;
}

.blue-label {
  color: #1B6481;
  font-weight: 600;
  font-size: 18px;
}

.orange-label {
  color: #C93E00;
  font-weight: 600;
  font-size: 18px;
}

.blue-label-children {
  font-size: 16px;
}

.trash-button {
  border: none;
  background: none;
  color: #1B6481;
  align-self: center;
  cursor: pointer;
}

hr {
  width: 100%;
  opacity: 1;
}

.addChildrenBox {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border: lightgray 1px solid;
  border-radius: 8px;
  padding: 10px 10px 10px 30px;
  margin-top: 10px;
}