/***************************************************************
* The idea is to have some global styles applicable to all components
* and to have local styles specific to each component.
* each component & page should have its own css file.
* App.css and index.css should be considered 'global' styles.
* index.css is broader than App.css, App.css should specify 
* initial styling for all components. index.css generally
* specifies the styling for HTML elements.
****************************************************************/
.hidden {
  display: none;
}

.text-input-wide {
  width: 337px;
  height: 50px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  background: #EAEDF0 0 0 no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  letter-spacing: 0;
  padding-left: 10px;
  color: #1B6481;
  border: 1px solid #FFFFFF;
}

.button-loading .backcontinue-button-text {
  visibility: hidden;
  opacity: 0;
}

.button-loading::after {
  content: "";
  position: absolute;
  width: 32px;
  height: 32px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #FFFFFF;
  border-radius: 50%;
  animation: button-loading-spinner 0.75s linear infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

/*noinspection CssUnusedSymbol*/

/*noinspection CssUnusedSymbol*/

.text-input {
  height: 50px;
  font-size: 1rem;
  transform: matrix(1, 0, 0, 1, 0, 0);
  background: #EAEDF0 0 0 no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  letter-spacing: 0;
  color: #1B6481;
  border: 1px solid #FFFFFF;
}

.text-input:focus-visible {
  outline: #D8EEF2 1px solid;
}

.text-input.error {
  border: 1px solid #FF5638;
}

.white-card {
  background: #FFFFFF 0 0 no-repeat padding-box;
  border: 1px solid #E5E5E5;
  border-radius: 12px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  opacity: 1;
  padding: 24px;
}